/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: black;
  font-family: 'Roboto', sans-serif;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

h1, .h1 {
  margin-bottom: 2rem;
}

h2, .h2 {
  margin-bottom: 2rem;
  margin-top: 2rem;
  color: #e84e24;
}

h3, .h3 {
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

h4, .h4 {
  font-weight: 400;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

h5, .h5 {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

h6, .h6 {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

.btn-primary {
  background-color: #e84e24;
  border-color: #e84e24;
  border-radius: 0;
}

.btn-primary:hover {
  background-color: #ad3312;
  border-color: #ad3312;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #ad3312;
  border-color: #ad3312;
}

.btn-secondary {
  background-color: black;
  border-color: black;
  border-radius: 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: #e84e24;
}

img {
  max-width: 100%;
}

img.img-lightbox {
  cursor: zoom-in;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Header Template Styles] */
/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Navigation Template Styles] */
.header {
  position: relative;
  z-index: 4444;
}

.header h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: white;
}

.header h4 a {
  color: white;
  text-decoration: none;
}

.header h4 a:hover {
  color: #e84e24;
}

.header .navbar .navbar-toggler {
  color: white;
  border-color: white;
  width: 100%;
}

.header .navbar .navbar-collapse .navbar-nav .dropdown-menu {
  text-align: center;
  width: 250px;
  max-width: 250px;
  right: auto;
  left: 50%;
  transform: translate(-50%, 0);
}

.header .navbar .navbar-collapse .navbar-nav .dropdown-menu .active {
  background-color: #e84e24;
}

.header .navbar .navbar-collapse .navbar-nav .dropdown-menu .dropdown-item:active {
  background-color: #e84e24;
}

.header .navbar .navbar-collapse .navbar-nav .active .nav-link {
  color: #e84e24 !important;
}

.header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: black;
}

.header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  color: #e84e24;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    background-color: black;
  }
  .header .navbar .navbar-toggler {
    color: white;
    border-color: white;
    width: 100%;
  }
  .header .navbar .navbar-collapse .navbar-nav .dropdown-menu {
    width: 100%;
    max-width: 100%;
    right: 0;
    left: 0;
    transform: translate(0, 0);
  }
  .header .navbar .navbar-collapse .navbar-nav .dropdown-menu .active {
    background-color: #e84e24;
  }
  .header .navbar .navbar-collapse .navbar-nav .active .nav-link {
    color: #e84e24 !important;
  }
  .header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: white;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  position: relative;
  z-index: 9999;
}

.page .nav h5 {
  margin-top: 0;
}

.page .nav .nav-itemactive .nav-link {
  padding-left: 0;
  padding-right: 0;
  color: #e84e24;
}

.page .nav .nav-item .nav-link {
  padding-left: 0;
  padding-right: 0;
  color: #333;
}

.page .nav .nav-item .nav-link:hover {
  color: #e84e24;
}

.tabbed-content {
  width: 100%;
  background-color: black;
  color: white;
  padding: 3rem 0;
  margin-bottom: 2rem;
}

.tabbed-content .main-content {
  padding: 0 10%;
}

.tabbed-content .main-content h1, .tabbed-content .main-content .h1, .tabbed-content .main-content h2, .tabbed-content .main-content .h2, .tabbed-content .main-content h3, .tabbed-content .main-content .h3, .tabbed-content .main-content h4, .tabbed-content .main-content .h4 {
  margin-top: 0;
  color: white;
}

.tabbed-content .main-content .nav li {
  border-top: 1px solid white;
  padding: 0.5rem 0;
  font-weight: 300;
}

.tabbed-content .main-content .nav a {
  color: white;
  text-decoration: none;
}

.tabbed-content .main-content .nav a .active, .tabbed-content .main-content .nav a:hover {
  color: #e84e24;
  text-decoration: none;
}

.main-content {
  padding: 0 10%;
}

.main-subpage-content {
  padding-left: 10%;
}

.custom-main-content {
  padding: 0 0 0 10%;
}

.body-content {
  background-color: #e3e3e3;
  clip-path: polygon(0 0, 95% 0, 85% 100%, 0% 100%);
  margin-bottom: 2rem;
}

.body-content h1 {
  color: black;
  margin-top: 0;
}

.body-content h2 {
  color: black;
  margin-top: 0;
}

.body-content h3 {
  color: black;
  margin-top: 0;
}

.padding-f {
  margin-bottom: 10rem;
}

.testimonial-quote {
  padding: 3rem 0;
}

.testimonial-quote p {
  line-height: 2rem;
  font-size: 1.3rem;
}

.free-quote {
  background-color: #24c5e8;
  padding: 3rem 0;
  position: relative;
}

.free-quote:before {
  position: absolute;
  content: ' ';
  top: 0;
  left: -10%;
  width: 20%;
  height: 100%;
  background-color: #24c5e8;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
}

.free-quote h3 {
  margin-top: 0;
  font-weight: 500;
  font-style: italic;
  color: white;
}

.free-quote-form .form-control {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0;
  color: black;
  border-color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  padding: 0.75rem 0.75rem;
  height: calc(1.5em + 1.5rem + 2px);
}

.swiper-container {
  width: 100%;
  background-color: black;
  color: white;
  padding: 2rem 0;
  position: relative;
}

.swiper-container:after {
  clip-path: polygon(35% 0, 100% 0, 100% 100%, 0% 100%);
  position: absolute;
  content: ' ';
  width: 20%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: white;
}

.swiper-container:before {
  clip-path: polygon(1% 0, 100% 0, 100% 100%, 0% 100%);
  position: absolute;
  content: ' ';
  width: 20%;
  height: 3%;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: #24c5e8;
}

.swiper-container .swiper-wrapper .swiper-slide {
  clip-path: polygon(0 0, 98.5% 0%, 98.5% 100%, 0% 100%);
}

.swiper-container .rotator-text {
  position: relative;
}

.swiper-container .rotator-text:before {
  position: absolute;
  width: 150%;
  height: 1px;
  z-index: 9;
  content: ' ';
  background-image: url("/images/Line.svg");
  top: -40px;
  left: -13%;
}

.swiper-container .rotator-text:after {
  position: absolute;
  width: 150%;
  height: 1px;
  z-index: 9;
  content: ' ';
  background-image: url("/images/Line.svg");
  bottom: -40px;
  left: -13%;
}

.swiper-container .swiper-pagination .swiper-pagination-bullet {
  background: #e3e3e3;
  width: 10px;
  height: 10px;
  opacity: 1;
}

.swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #e84e24;
}

.swiper-container .swiper-slide img {
  width: 100%;
  position: relative;
  border: solid 5px black;
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 1% 100%);
  z-index: 22;
}

.subpage-banner img {
  width: 100%;
  z-index: 999;
  position: relative;
  border: solid 5px black;
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
}

.subpage-banner {
  background-color: black;
  color: white;
  padding: 2rem 0;
  position: relative;
}

.subpage-banner .subpage-banner-title {
  position: relative;
  padding-left: 2rem;
}

.subpage-banner .subpage-banner-title:before {
  position: absolute;
  width: 150%;
  height: 1px;
  z-index: 9;
  content: ' ';
  background-image: url("/images/Line.svg");
  top: -20px;
  left: 0;
}

.subpage-banner .subpage-banner-title:after {
  position: absolute;
  width: 150%;
  height: 1px;
  z-index: 9;
  content: ' ';
  background-image: url("/images/Line.svg");
  bottom: -20px;
  left: 0;
}

.subpage-banner:after {
  clip-path: polygon(35% 0, 100% 0, 100% 100%, 0% 100%);
  position: absolute;
  content: ' ';
  width: 20%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: white;
}

.gallery-hover {
  position: relative;
}

.gallery-link {
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  display: none;
  overflow: hidden;
}

.hover-caption .caption {
  position: absolute;
  color: white;
  text-align: center;
  padding: 15%;
  top: 50%;
  transform: translateY(-50%);
}

.padding-left {
  padding-left: 10%;
}

.feature-nav a {
  text-decoration: none;
  color: white;
}

.feature-nav .item-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.feature-nav .item-background:before {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.feature-nav .item {
  padding: 5rem 2rem;
  max-height: 229px;
  box-shadow: 5px 10px;
  position: relative;
  z-index: 5;
}

.feature-nav .item h5, .feature-nav .item .h5 {
  color: white;
  margin: 0;
}

.feature-nav .item p {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.feature-nav .item .feature-button {
  position: absolute;
  content: ' ';
  bottom: -15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.free-subpage-quote {
  background-color: #24c5e8;
  padding: 0;
  position: relative;
  margin-top: -20%;
}

.free-subpage-quote h3 {
  color: white;
}

.logo-rotator {
  position: absolute;
  top: 10px;
  left: 4%;
  width: 25%;
  content: ' ';
}

/* Large desktop */
@media (max-width: 1448px) {
  .swiper-container .rotator-text {
    position: relative;
  }
  .swiper-container .rotator-text:before {
    position: absolute;
    width: 150%;
    height: 1px;
    z-index: 9;
    content: ' ';
    background-image: url("/images/Line.svg");
    top: -20px;
    left: -13%;
  }
  .swiper-container .rotator-text:after {
    position: absolute;
    width: 150%;
    height: 1px;
    z-index: 9;
    content: ' ';
    background-image: url("/images/Line.svg");
    bottom: -20px;
    left: -13%;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .swiper-container:before {
    display: none;
  }
  .swiper-container .rotator-text {
    position: relative;
  }
  .swiper-container .rotator-text:before {
    display: none;
  }
  .swiper-container .rotator-text:after {
    display: none;
  }
  .swiper-container .rotator-text h1 {
    font-size: 1.5rem;
  }
  .swiper-container .rotator-text h2 {
    font-size: 1.3rem;
  }
  .free-subpage-quote {
    margin-top: 0;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .body-content {
    background-color: #e3e3e3;
    clip-path: none;
    margin-bottom: 2rem;
  }
  .swiper-container {
    padding-top: 0;
  }
  .swiper-container:before {
    display: none;
  }
  .swiper-container .rotator-text {
    position: relative;
  }
  .swiper-container .rotator-text:before {
    display: none;
  }
  .swiper-container .rotator-text:after {
    display: none;
  }
  .swiper-container .rotator-text h1 {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .swiper-container .rotator-text h2 {
    font-size: 1rem;
    margin: 1rem 0;
  }
  .free-subpage-quote {
    margin-top: 0;
  }
  .main-subpage-content {
    padding-left: 1rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .body-content {
    background-color: #e3e3e3;
    clip-path: none;
    margin-bottom: 0;
  }
  .main-content {
    padding: 0 1.5rem;
  }
  .subpage-banner img {
    width: 100%;
    z-index: 999;
    position: relative;
    border: none;
    clip-path: none;
  }
  .subpage-banner {
    background-color: black;
    color: white;
    padding: 0;
    position: relative;
  }
  .subpage-banner .subpage-banner-title {
    display: none;
  }
  .subpage-banner .subpage-banner-title:before {
    display: none;
  }
  .subpage-banner .subpage-banner-title:after {
    display: none;
  }
  .subpage-banner:after {
    display: none;
  }
  .subpage-banner .padding-left {
    padding: 0;
  }
  .padding-left {
    padding-left: 0;
  }
  .swiper-container {
    padding: 0 0 2rem 0;
  }
  .swiper-container:after {
    display: none;
  }
  .swiper-container:before {
    display: none;
  }
  .swiper-container .rotator-text {
    padding: 1rem 1rem;
  }
  .swiper-container .rotator-text h1 {
    font-size: 1.5rem;
  }
  .swiper-container .rotator-text h2 {
    font-size: 1.3rem;
  }
  .swiper-container .rotator-text:before {
    position: absolute;
    width: 150%;
    height: 1px;
    z-index: 9;
    content: ' ';
    background-image: url("/images/Line.svg");
    top: -40px;
    left: -13%;
  }
  .swiper-container .rotator-text:after {
    position: absolute;
    width: 150%;
    height: 1px;
    z-index: 9;
    content: ' ';
    background-image: url("/images/Line.svg");
    bottom: -40px;
    left: -13%;
  }
  .swiper-container .swiper-slide img {
    width: 100%;
    position: relative;
    border: solid 5px black;
    clip-path: none;
    z-index: 22;
  }
  .padding-f {
    margin-bottom: 0;
  }
  .custom-main-content {
    padding: 0 1rem;
  }
  .main-subpage-content {
    padding-left: 1rem;
  }
  .free-subpage-quote {
    margin-top: 0;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  background: black;
  padding: 3rem 1rem;
  position: relative;
}

.footer .fab {
  font-size: 1.5rem;
}

.footer .footer-logo {
  position: absolute;
  top: -43px;
  left: 2rem;
  max-width: 300px;
}

.footer span {
  margin-right: 1rem;
  font-size: 1.5rem;
  color: white;
  font-style: italic;
}

.footer span a {
  color: white;
  text-decoration: none;
}

.footer span a:hover {
  color: #e84e24;
}

.footer .nav .nav-link {
  color: white;
  font-weight: 400;
  font-style: italic;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.footer .nav .nav-link:first-child {
  padding-left: 0;
}

.footer .nav .nav-link:hover {
  color: #e84e24;
}

.footer .footer-notes {
  color: white;
  font-weight: 300;
  font-style: italic;
  font-size: 0.9rem;
}

.footer .footer-notes a {
  color: white;
  text-decoration: none;
}

.footer .footer-notes a:hover {
  color: #e84e24;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    width: 100%;
    background: black;
    padding: 3rem 1rem;
    position: relative;
  }
  .footer .footer-logo {
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
  }
  .footer .nav .nav-link {
    color: white;
    font-weight: 400;
    font-style: italic;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  .footer .nav .nav-link:first-child {
    padding-left: 1.5rem;
  }
  .footer .footer-notes {
    color: white;
    font-weight: 300;
    font-style: italic;
    font-size: 0.9rem;
  }
  .footer .footer-notes a {
    color: white;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}

.form-group.validation-error {
  box-shadow: none;
  background: none;
  padding: 0;
}

/*===== Footer Contact Form ====*/
#footer-contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  padding-bottom: 8rem;
}

#footer-contact .contact-panel ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#footer-contact .contact-panel ul li {
  margin: 0 0 20px 0;
}

#footer-contact .contact-panel .image-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

#footer-contact .contact-panel .image-grid img {
  max-width: 100%;
}

#footer-contact a {
  color: #e84e24;
}

@media (max-width: 885px) {
  #footer-contact {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 1rem;
  }
  .contact-panel {
    margin-bottom: 30px;
  }
}

/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/

	$color-primary: rgb(0, 0, 0);
	$color-white: rgb(255, 255, 255);
	$color-blue: rgb(36, 197, 232);
	$color-orange: rgb(232, 78, 36);
	$color-grey: rgb(227, 227, 227);

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-primary;
		font-family: 'Roboto', sans-serif;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;
	}

	h1, .h1 {
		margin-bottom: 2rem;
	}

	h2, .h2 {
		margin-bottom: 2rem;
		margin-top: 2rem;
		color: $color-orange;
	}

	h3, .h3 {
		font-weight: 300;
		margin-bottom: 2rem;
		margin-top: 2rem;
	}

	h4, .h4 {
		font-weight: 400;
		margin-bottom: 2rem;
		margin-top: 2rem;
	}

	h5, .h5 {
		margin-bottom: 0.5rem;
		margin-top: 2rem;
	}

	h6, .h6 {
		margin-bottom: 0.5rem;
		margin-top: 2rem;
	}

	.btn-primary {
		background-color: $color-orange;
		border-color: $color-orange;
		border-radius: 0;

		&:hover {
			background-color: darken($color-orange, 15%);
			border-color: darken($color-orange, 15%);
		}
	}

	.btn-primary:not(:disabled):not(.disabled):active {
		background-color: darken($color-orange, 15%);
		border-color: darken($color-orange, 15%);
	}

	.btn-secondary {
		background-color: $color-primary;
		border-color: $color-primary;
		border-radius: 0;
	}

	a {
		text-decoration: none;
		&:hover {
			color: $color-orange;
		}
	}

	img
	{
		max-width: 100%;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Header Template Styles] */



	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */
	.header {
		position: relative;
		z-index: 4444;

		h4 {
			margin-top: 1rem;
			margin-bottom: 0.5rem;
			color: white;

			a {
				color: white;
				text-decoration: none;

				&:hover {
					color: $color-orange;
				}
			}
		}
		.navbar {
			.navbar-toggler {
				color: white;
				border-color:  white;
				width: 100%;
			}
			.navbar-collapse {
				.navbar-nav {

					.dropdown-menu {
						text-align: center;
						width: 250px;
						max-width: 250px;
						right: auto;
						left: 50%;
						transform: translate(-50%, 0);

						.active {
							background-color: $color-orange;
						}

						.dropdown-item {

							&:active {
								background-color: $color-orange;
							}
						}
					}

					.active {
						.nav-link {
							color: $color-orange !important;
						}
					}
					.nav-item {
						.nav-link {
							color: $color-primary;

							&:hover {
								color: $color-orange;
							}
						}
					}
				}
			}
		}
	}


	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

		.header {
			background-color: $color-primary;

			.navbar {
				.navbar-toggler {
					color: white;
					border-color:  white;
					width: 100%;
				}
				.navbar-collapse {
					.navbar-nav {
						.dropdown-menu {
							width: 100%;
							max-width: 100%;
							right: 0;
							left: 0;
							transform: translate(0, 0);

							.active {
								background-color: $color-orange;
							}
						}

						.active {
							.nav-link {
								color: $color-orange !important;
							}
						}
						.nav-item {
							.nav-link {
								color: white;
							}
						}
					}
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page {
		position: relative;
		z-index: 9999;
		.nav {
			h5 {
				margin-top: 0;
			}

			.nav-itemactive {
				.nav-link {
					padding-left: 0;
					padding-right: 0;

					color: $color-orange;
				}
			}

			.nav-item {
				.nav-link {
					padding-left: 0;
					padding-right: 0;

					color: #333;

					&:hover {
						color: $color-orange;
					}
				}
			}
		}

	}

	.tabbed-content
	{
		width: 100%;

		background-color: $color-primary;
		color: $color-white;
		padding: 3rem 0;
		margin-bottom: 2rem;

		.main-content {
			padding: 0 10%;

			h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
				margin-top: 0;
				color: $color-white;
			}

			.nav {
				li {
					border-top: 1px solid white;
					padding: 0.5rem 0;
					font-weight: 300;
				}
				a {
					color: $color-white;
					text-decoration: none;

					.active, &:hover {
						color: $color-orange;
						text-decoration: none;
					}
				}
			}
		}
	}

	.main-content {
		padding: 0 10%;
	}

	.main-subpage-content {
		padding-left: 10%;
	}

	.custom-main-content {
			padding: 0 0 0 10%;
	}

	.body-content {
		background-color: $color-grey;
		clip-path: polygon(0 0, 95% 0, 85% 100%, 0% 100%);
		margin-bottom: 2rem;

		h1 {
			color: $color-primary;
			margin-top: 0;
		}
		h2 {
			color: $color-primary;
			margin-top: 0;
		}
		h3 {
			color: $color-primary;
			margin-top: 0;
		}
	}

	.padding-f {
		margin-bottom: 10rem;
	}

	.testimonial-quote {
		padding: 3rem 0;

		p {
			line-height: 2rem;
			font-size: 1.3rem;
		}
	}

	.free-quote {
		background-color: $color-blue;
		padding: 3rem 0;
		position: relative;
		//z-index: -1;

		&:before {
			position: absolute;
			content: ' ';
			top: 0;
			left: -10%;
			width: 20%;
			height: 100%;
			background-color: $color-blue;
			clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
		}

		h3 {
			margin-top: 0;
			font-weight: 500;
			font-style: italic;
			color: $color-white;
		}
	}

	.free-quote-form {
		//.col-md-6 {
		//	padding: 0.5rem 0.5rem;
		//}
		//.col-12 {
		//	padding: 0.5rem 0.5rem;
		//}

		.form-control {
			background-color: rgba(255, 255, 255, 0.7);
			border-radius: 0;
			color: $color-primary;
			border-color: rgba(255, 255, 255, 0.7);
			font-size: 0.9rem;
			padding: 0.75rem 0.75rem;
			height: calc(1.5em + 1.5rem + 2px);
		}
	}

	.swiper-container
	{
		width: 100%;
		background-color: $color-primary;
		color: white;
		padding: 2rem 0;
		position: relative;

		&:after {
			clip-path: polygon(35% 0, 100% 0, 100% 100%, 0% 100%);
			position: absolute;
			content: ' ';
			width: 20%;
			height: 100%;
			top: 0;
			right: 0;
			background-color: $color-white;
		}

		&:before {
			clip-path: polygon(1% 0, 100% 0, 100% 100%, 0% 100%);
			position: absolute;
			content: ' ';
			width: 20%;
			height: 3%;
			bottom: 0;
			right: 0;
			z-index: 1;
			background-color: $color-blue;
		}

		.swiper-wrapper {
			.swiper-slide {
				clip-path: polygon(0 0, 98.5% 0%, 98.5% 100%, 0% 100%);

			}
		}

		.rotator-text {
			position: relative;

			&:before {
				position: absolute;
				width: 150%;
				height: 1px;
				z-index: 9;
				content: ' ';
				background-image: url("/images/Line.svg");
				top: -40px;
				left: -13%;

			}
			&:after {
				position: absolute;
				width: 150%;
				height: 1px;
				z-index: 9;
				content: ' ';
				background-image: url("/images/Line.svg");
				bottom: -40px;
				left: -13%;

			}
		}

		.swiper-pagination {
			.swiper-pagination-bullet {
				background: $color-grey;
				width: 10px;
				height: 10px;
				opacity: 1;
			}

			.swiper-pagination-bullet-active {
				background: $color-orange;
			}
		}
	}

		.swiper-container .swiper-slide img
		{
			width: 100%;
			position: relative;
			border: solid 5px $color-primary;
			clip-path: polygon(20% 0, 100% 0, 100% 100%, 1% 100%);
			z-index: 22;
		}

	.subpage-banner img
	{
		width: 100%;
		z-index: 999;
		position: relative;
		border: solid 5px $color-primary;
		clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
	}

	.subpage-banner {
		background-color: $color-primary;
		color: white;
		padding: 2rem 0;
		position: relative;

		.subpage-banner-title {
			position: relative;
			padding-left: 2rem;

			&:before {
				position: absolute;
				width: 150%;
				height: 1px;
				z-index: 9;
				content: ' ';
				background-image: url("/images/Line.svg");
				top: -20px;
				left: 0;

			}
			&:after {
				position: absolute;
				width: 150%;
				height: 1px;
				z-index: 9;
				content: ' ';
				background-image: url("/images/Line.svg");
				bottom: -20px;
				left: 0;

			}
		}

		&:after {
			clip-path: polygon(35% 0, 100% 0, 100% 100%, 0% 100%);
			position: absolute;
			content: ' ';
			width: 20%;
			height: 100%;
			top: 0;
			right: 0;
			background-color: $color-white;
		}
	}

	.gallery-hover
	{
		position: relative;
	}

	.gallery-link
	{
		position: relative;
		width: 100%;
		height: 100%;
	}

	.hover-caption
	{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.75);
		z-index: 10;
		display: none;
		overflow: hidden;

		.caption
		{
			position: absolute;
			color: white;
			text-align: center;
			padding: 15%;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.padding-left {
		padding-left: 10%;
	}


	.feature-nav {

		a {
			text-decoration: none;
			color: white;
		}

		.item-background {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			position: relative;

			&:before {
				position: absolute;
				content: ' ';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;

				background-color: rgba(0, 0, 0, 0.6);
			}
		}

		.item {
			padding: 5rem 2rem;
			max-height: 229px;
			box-shadow: 5px 10px;
			position: relative;
			z-index: 5;

			h5, .h5 {
				color: $color-white;
				margin: 0;
			}

			p {
				font-size: 0.9rem;
				margin-top: 0.5rem;
			}

			.feature-button {
				position: absolute;
				content: ' ';
				bottom: -15%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

	}

	.free-subpage-quote {
		background-color: $color-blue;
		padding: 0;
		position: relative;
		margin-top: -20%;
		//z-index: -1;

		h3 {
			color: $color-white;
		}
	}

	.logo-rotator {
		position: absolute;
		top: 10px;
		left: 4%;
		width: 25%;
		content: ' ';

	}


	/* Large desktop */
	@media (min-width: 1200px) {

	}

@media (max-width: 1448px) {

	.swiper-container {
		.rotator-text {
			position: relative;

			&:before {
				position: absolute;
				width: 150%;
				height: 1px;
				z-index: 9;
				content: ' ';
				background-image: url("/images/Line.svg");
				top: -20px;
				left: -13%;

			}
			&:after {
				position: absolute;
				width: 150%;
				height: 1px;
				z-index: 9;
				content: ' ';
				background-image: url("/images/Line.svg");
				bottom: -20px;
				left: -13%;

			}
		}
	}

}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.swiper-container {

			&:before {
				display: none;
			}

			.rotator-text {
				position: relative;

				&:before {
					display: none;

				}
				&:after {
					display: none;

				}

				h1 {
					font-size: 1.5rem;
				}

				h2 {
					font-size: 1.3rem;
				}

			}
		}

		.free-subpage-quote {
			margin-top: 0;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.body-content {
			background-color: $color-grey;
			clip-path: none;
			margin-bottom: 2rem;
		}
		.swiper-container {
			padding-top: 0;

			&:before {
				display: none;
			}

			.rotator-text {
				position: relative;

				&:before {
					display: none;

				}
				&:after {
					display: none;

				}

				h1 {
					font-size: 1rem;
					margin-bottom: 0;
				}

				h2 {
					font-size: 1rem;
					margin: 1rem 0;
				}

			}
		}
		.free-subpage-quote {
			margin-top: 0;
		}

		.main-subpage-content {
			padding-left: 1rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.body-content {
			background-color: $color-grey;
			clip-path: none;
			margin-bottom: 0;
		}

		.main-content {
			padding: 0 1.5rem;
		}

		.subpage-banner img
		{
			width: 100%;
			z-index: 999;
			position: relative;
			border: none;
			clip-path: none;
		}

		.subpage-banner {
			background-color: $color-primary;
			color: white;
			padding: 0;
			position: relative;

			.subpage-banner-title {
				display: none;

				&:before {
					display: none;

				}
				&:after {
					display: none;

				}
			}

			&:after {
				display: none;
			}

			.padding-left {
				padding: 0;
			}
		}
		.padding-left {
			padding-left: 0;
		}

		.swiper-container
		{
			padding: 0 0 2rem 0;

			&:after {
				display: none;
			}
			&:before {
				display: none;
			}

			.rotator-text {
				padding: 1rem 1rem;

				h1 {
					font-size: 1.5rem;
				}

				h2 {
					font-size: 1.3rem;
				}

				&:before {
					position: absolute;
					width: 150%;
					height: 1px;
					z-index: 9;
					content: ' ';
					background-image: url("/images/Line.svg");
					top: -40px;
					left: -13%;

				}
				&:after {
					position: absolute;
					width: 150%;
					height: 1px;
					z-index: 9;
					content: ' ';
					background-image: url("/images/Line.svg");
					bottom: -40px;
					left: -13%;

				}
			}
		}
		.swiper-container .swiper-slide img
		{
			width: 100%;
			position: relative;
			border: solid 5px $color-primary;
			clip-path: none;
			z-index: 22;
		}
		.padding-f {
			margin-bottom: 0;
		}

		.custom-main-content {
			padding: 0 1rem;
		}

		.main-subpage-content {
			padding-left: 1rem;
		}
		.free-subpage-quote {
			margin-top: 0;
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		background: $color-primary;
		padding: 3rem 1rem;
		position: relative;
		.fab {
			font-size: 1.5rem;
		}
		.footer-logo {
			position: absolute;
			top: -43px;
			left: 2rem;
			max-width: 300px;
		}
		span {
			margin-right: 1rem;
			font-size: 1.5rem;
			color: $color-white;
			font-style: italic;

			a {
				color: white;
				text-decoration: none;

				&:hover {
					color: $color-orange;
				}
			}
		}
		.nav {
			.nav-link {
				color: $color-white;
				font-weight: 400;
				font-style: italic;
				text-transform: uppercase;
				font-size: 0.9rem;

				&:first-child {
					padding-left: 0;
				}

				&:hover {
					color: $color-orange;
				}
			}
		}

		.footer-notes {
			color: $color-white;
			font-weight: 300;
			font-style: italic;
			font-size: 0.9rem;

			a {
				color: $color-white;
				text-decoration: none;
				&:hover {
					color: $color-orange;
				}
			}
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			width: 100%;
			background: $color-primary;
			padding: 3rem 1rem;
			position: relative;

			.footer-logo {
				text-align: center;
				position: relative;
				top: 0;
				left: 0;
				max-width: 100%;
			}

			.nav {
				.nav-link {
					color: $color-white;
					font-weight: 400;
					font-style: italic;
					text-transform: uppercase;
					font-size: 0.9rem;

					&:first-child {
						padding-left: 1.5rem;
					}

				}
			}

			.footer-notes {
				color: $color-white;
				font-weight: 300;
				font-style: italic;
				font-size: 0.9rem;

				a {
					color: $color-white;
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}

.form-group.validation-error {
	box-shadow: none;
	background: none;
	padding: 0;
}

/*===== Footer Contact Form ====*/

#footer-contact {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 30px;
	padding-bottom: 8rem;

	.contact-panel {
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				margin: 0 0 20px 0;
			}
		}

		.image-grid {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: 4px;
			grid-row-gap: 4px;

			img {
				max-width: 100%;
			}
		}
	}

	a {
		color: #e84e24;
	}
}


@media (max-width: 885px) {
	#footer-contact {
		grid-template-columns: repeat(1, 1fr);
		padding-bottom: 1rem;
	}

	.contact-panel {
		margin-bottom: 30px;
	}
}
